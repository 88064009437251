import { useCascaderAreaData } from '@vant/area-data';
import { postCertification } from "@/api";
export default {
  data() {
    return {
      name: "",
      idcard: "",
      datePopShow: false,
      options: useCascaderAreaData(),
      cascaderPop: false,
      cascaderValue: "",
      cascaderValue1: ""
    };
  },
  methods: {
    getDate({
      selectedValues
    }) {
      this.idDate = selectedValues.join('-');
      this.datePopShow = false;
    },
    onFinish({
      selectedOptions
    }) {
      this.cascaderValue = selectedOptions.map(option => option.text).join('');
      this.cascaderPop = false;
    },
    certification() {
      if (!this.name) {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.idcard) {
        this.$toast("请输入身份证号");
        return;
      }
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      postCertification({
        name: this.name,
        idcard: this.idcard,
        address: this.cascaderValue + this.cascaderValue1
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.$router.go(-1);
      });
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  }
};